// This is for the steps slider on the Roadmap page
function Slider(slider, autoPlay, navigation){
  if(!(slider instanceof Element)){
    throw new Error('No slider passed in');
  }

  let prev;
  let current;
  let next;
  let afterPrev;

  const slides = slider.querySelector('.slides');
  const currentSlide = slides.querySelector('.current');

  function setMaxHeight(){
    var heights = [];
    Array.from(slides.children).forEach(function (element) { 
      heights.push(element.clientHeight);
    }); 
    var maxHeight = Math.max.apply(Math, heights);
    var value = `${maxHeight}px`;
    slides.style.height = value;

  }

  function startSlider(){
    current = currentSlide || slides.firstElementChild;
    next = current.nextElementSibling;
    prev = current.previousElementSibling || slides.lastElementChild;
    afterPrev = prev.previousElementSibling || slides.lastElementChild;
  }

  function applyClasses(){
    current.classList.add('current');
    prev.classList.add('prev');
    next.classList.add('next');
    afterPrev.classList.add('after-prev');
  }
 
  function move(direction){
    
    const toRemove = ['after-prev', 'prev', 'current', 'next'];
    prev.classList.remove(...toRemove);
    current.classList.remove(...toRemove);
    next.classList.remove(...toRemove);
    afterPrev.classList.remove(...toRemove);

    if(direction === 'back'){
      [afterPrev, prev, current, next] = [
        afterPrev.previousElementSibling || slides.lastElementChild,
        prev.previousElementSibling || slides.lastElementChild,
        prev,
        current,
      ];
    }else{
      [afterPrev, prev, current, next] = [
        prev,
        current,
        next,
        next.nextElementSibling || slides.firstElementChild,
      ];
    }

    applyClasses();
  }

  startSlider();
  applyClasses();

  if(autoPlay){
    setInterval(function(){ 
      move();
    }, 4000);
  }

  if(navigation){
    const prevButton = slider.querySelector('.btn-prev');
    const nextButton = slider.querySelector('.btn-next');

    prevButton.addEventListener('click', () => move('back'));
    nextButton.addEventListener('click', () => move());

    window.addEventListener('resize', setMaxHeight);
    window.addEventListener('load', setMaxHeight);
  }
}

document.addEventListener('DOMContentLoaded', function(){
  let planSlider = document.querySelector('.section__content__plan');
  if (planSlider) Slider(planSlider, false, true);

  let reportSlider = document.querySelector('.section__content__report');
  if (reportSlider) Slider(reportSlider, false, true);
})
