document.addEventListener("DOMContentLoaded", () => {
  const nameInput = document.querySelector('[name="contact[name]"');
  const emailInput = document.querySelector('[name="contact[email]"');
  const messageInputContainer = document.getElementById("message-input");
  const contactForm = document.querySelector("#new_contact");
  const validationBox = document.querySelector("#new_contact #validation-box");

  if (contactForm) {
    contactForm.addEventListener("ajax:success", showSuccessMessage);
    contactForm.addEventListener("ajax:error", showErrorMessage);
  }

  function showErrorMessage(event) {
    console.log(event);
    validationBox.classList.remove("expand");
    let formError = document.querySelector("#new_contact .form-error");
    if (formError) {
      formError.remove();
    }
    var msg =
      '<div class="form-error"><p><i class="fa fa-exclamation-triangle"></i> There was a problem: ' +
      event.detail[0] +
      "</p></div>";
    validationBox.focus();
    validationBox.innerHTML = msg;
    validationBox.classList.add("expand");
  }

  function showSuccessMessage() {
    validationBox.classList.remove("expand");
    document
      .querySelectorAll("div#contact-us > .contact-us--col")
      .forEach((element) => {
        element.classList.add("shrink");
      });

    if (document.querySelector("#contact-us-popup h1")) {
      document.querySelector("#contact-us-popup h1").innerHTML = "Cheers!";
    }

    validationBox.innerHTML =
      '<div class="form-success" id="contact-success-wrapper">Thank you! We will get back to you soon ;-) </div>';
    validationBox.focus();
    validationBox.classList.add("expand");

    if (!emailInput.value.includes("@ombulabs.com")) {
      const linkedin = "1510041";
      const twitter = "o3feb";
      const reddit = "Lead";

      conversionTracking(linkedin, twitter, reddit);
    }
  }

  function toggleMessageInputContainer() {
    if (
      emailInput.validationMessage === "" &&
      nameInput.value.trim() !== "" &&
      emailInput.value.trim() !== ""
    ) {
      messageInputContainer.classList.remove("hidden");
    }
  }

  if (emailInput) {
    emailInput.addEventListener("input", toggleMessageInputContainer);
  }

  if (nameInput) {
    nameInput.addEventListener("input", toggleMessageInputContainer);
  }

  if (contactForm) {
    const otherReferral = document.querySelector("#other_referral");
    const foundUsSource = document.querySelector("#found-us-source");
    const foundUs = document.querySelector("#found_us");
    const referrals = document.querySelector("#referrals");

    updateFoundUs("Google");
    otherReferral.addEventListener("input", (event) => {
      updateFoundUs(event.target.value);
    });

    referrals.addEventListener("change", (event) => {
      updateFoundUs(event.target.value);
    });

    foundUsSource.addEventListener("change", (event) => {
      const source = event.target.value;
      if (source == "Other") {
        updateFoundUs(otherReferral.value);
        referralsVisibility(false, true);
      } else if (source == "Referral") {
        updateFoundUs(referrals.value);
        referralsVisibility(true, false);
      } else {
        referralsVisibility(true, true);
        updateFoundUs(source);
      }
    });

    function updateFoundUs(source = "") {
      foundUs.value = source;
    }

    function referralsVisibility(other_referral, referral) {
      document.getElementById("other-referrals-input-div").hidden =
        other_referral;
      document.getElementById("referrals-input-div").hidden = referral;
    }
  }
});
