document.addEventListener('DOMContentLoaded', () => {
  // Smooth scroll behavior for all <a> tags that have a
  // hash attribute pointing to an element on the same page
  document.querySelectorAll('a').forEach(function(anchor) {
    anchor.addEventListener('click', function(event) {
      const hash = this.hash;
      if (hash && this.baseURI === window.location.href) {
        const pageHash = document.querySelector(hash);
        if (pageHash !== null) {
          event.preventDefault();
          scrollToId(hash);
        }
      }
    });
  });
});

const scrollToId = function(hash) {
  const element = document.querySelector(hash);
  if (element !== null) {
    element.classList.remove('hide');
    let menuHeight = document.querySelector('header').offsetHeight;
    let targetOffset = element.getBoundingClientRect().top + window.scrollY - menuHeight;

    window.scrollTo({
      top: targetOffset,
      behavior: 'smooth'
    });

    element.focus();
  }
};