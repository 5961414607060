const versionCheck = function () {
  const desiredSelect = document.getElementById("desired-version");
  const currentSelect = document.getElementById("current-version");
  const roadmapError = document.getElementById("roadmap-error");

  const currentVersion = parseInt(currentSelect.value);
  const desiredVersion = parseInt(desiredSelect.value);
  const minRailsVersion = parseInt(roadmapError.getAttribute("data-min-version"));
  const maxRailsVersion = parseInt(roadmapError.getAttribute("data-max-version"));
  let error;
  if (currentVersion === 0) {
    error = "Please provide current Rails version.";
  }
  else if (desiredVersion === 0) {
    error = "Please provide desired Rails version.";
  }
  else if (currentVersion < minRailsVersion || currentVersion > maxRailsVersion) {
    error = "Invalid current Rails version.";
  }
  else if (desiredVersion < minRailsVersion || desiredVersion > maxRailsVersion) {
    error = "Invalid desired Rails version.";
  }
  else if (currentVersion >= desiredVersion) {
    error = "The desired version must be greater than the current version.";
  }
  else {
    error = null;
  }

  if (error !== null) {
    roadmapError.classList.add("error");
    roadmapError.textContent = error;
  }
  else {
    roadmapError.classList.remove("error");
    roadmapError.textContent = null;
  }
};

document.addEventListener("DOMContentLoaded", () => {
  const priceWrapper = document.getElementById("roadmap-price");

  if (priceWrapper) {
    const totalSpan = priceWrapper.querySelector(".total");
    const subtotalSpan = priceWrapper.querySelector(".subtotal");
    const discountSpan = priceWrapper.querySelector(".discount");

    document.querySelectorAll(".roadmap_input").forEach((el) => {
      el.addEventListener("change", function () {
        const currentVersion = $("#current-version option:selected").val();
        const desiredVersion = $("#desired-version option:selected").val();
        versionCheck();

        if (currentVersion > 0 && desiredVersion > currentVersion) {
          const jumps = desiredVersion - currentVersion;
          let total = first_version_upgrade + additional_version_upgrade * (jumps - 1);

          if (discountSpan) {
            const discount = parseInt(discountSpan.dataset.amount);
            const subtotal = total;
            total = total - discount;

            discountSpan.innerText = `$${new Intl.NumberFormat("en-US").format(discount)} OFF`;
            subtotalSpan.innerText = `$${new Intl.NumberFormat("en-US").format(subtotal)}`;
          }

          totalSpan.innerText = `$${new Intl.NumberFormat("en-US").format(total)}`;
        } else {
          totalSpan.innerText = "";

          if (discountSpan) {
            discountSpan.innerText = "";
            subtotalSpan.innerText = "";
          }
        }
      });
    });
  }

  document.querySelectorAll('.roadmap-accordion__title').forEach(function(ele_title) {
    ele_title.addEventListener('click', function(event) {
      event.preventDefault();

      document.querySelectorAll('.roadmap-accordion__title a img').forEach(function(ele_img) {
        ele_img.classList.toggle('open');
      });

      // keeping this to jQuery as a solution for this is yet
      // to be thought of and we have another story to replace
      // these animations since we need a single solution to re-use
      $(".roadmap-accordion__body").slideToggle(900);
    });
  });
});
