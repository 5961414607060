// Newsletter conversions
document.addEventListener('DOMContentLoaded', () => {
  var linkedin = "1995810";
  var twitter = "o3fea";
  var reddit = "SignUp";

  // landing, blog mobile
  $(".formkit-ca").on("click", function() {
    $(".formkit-form").on("submit", function() {
      conversionTracking(linkedin, twitter, reddit);
    });
  });

  // blog sidebar
  $(".formkit-form").on("submit", function() {
    conversionTracking(linkedin, twitter, reddit);
  });
});

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

global.conversionTracking = function(linkedin, twitter, reddit) {
  var linkedinPixel =
    '<img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=1499842&conversionId=' +
    linkedin +
    '&fmt=gif" />';

  var source = getCookie("utm_source");
  console.log("utm_source = " + source);

  if (source === "linkedin") {
    console.log("LinkedIn conversion");
    $("body").append(linkedinPixel);
  } else if (source === "twttr") {
    console.log("Twitter conversion");
    twttr.conversion.trackPid(twitter, {
      tw_sale_amount: 0,
      tw_order_quantity: 0
    });
  } else if (source === "reddit") {
    console.log("Reddit conversion");
    rdt("track", reddit);
  }
}
