// All header behaviours
document.addEventListener('DOMContentLoaded', () => {
  const togglerElements = document.querySelectorAll('.navbar-toggler');
  const headerElement = document.querySelector('header');
  // Navbar toggle button
  togglerElements.forEach(function(togglerElement) {
    togglerElement.addEventListener('click', function() {
      togglerElement.classList.toggle('open');
      headerElement.classList.toggle('open');

      if (togglerElement.classList.contains('open')) {
        togglerElement.setAttribute('aria-label', 'Close navigation');
      } else {
        togglerElement.setAttribute('aria-label', 'Open navigation');
      }
    });
  });

  // Makes navbar sticky on load if scrollTop >= topSectionHeight
  const topSection = document.querySelector('.top-section');
  const header = document.querySelector('header');

  let topSectionHeight = topSection.offsetHeight / 2;
  let scrollTop = window.pageYOffset || document.documentElement.scrollTop;

  if (scrollTop >= topSectionHeight) {
    header.classList.add('fixed');
  }

  // Makes navbar sticky on scroll if scrollTop >= topSectionHeight
  document.addEventListener("scroll", function() {

    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    
    if (scrollTop >= topSectionHeight) {
      header.classList.add('fixed');
    } else if (document.querySelector('.navbar-collapse.in')) {
      header.classList.add('fixed');
    } else {
      header.classList.remove('fixed');
    }
  });

  // Closes menu on mobile when clicking a menu item
  const navLinks = document.querySelectorAll('.navbar-nav a');
  const togglerElement = document.querySelector('.navbar-toggler');
  const collapseElement = document.querySelector('.navbar-collapse');

  navLinks.forEach(function(navLink) {
    navLink.addEventListener('click', function() {
      togglerElement.classList.remove('open');
      collapseElement.classList.remove('in');
      togglerElement.classList.add('collapsed');
    });
  });
});
