document.addEventListener("DOMContentLoaded", function () {
  document.querySelectorAll(".service-card").forEach((element) => {
    element.addEventListener("click", (event) => {
      event.preventDefault();
      const service_details_card = document.querySelector(`${element.hash} .scroll-anchor`);

      service_details_card.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    })
  })

  document.querySelectorAll(".see-more-target").forEach((el) => {
    el.addEventListener("click", (event) => {
      event.preventDefault();
      const element = event.target.closest(".service-details-card");

      if (element.classList.contains("see-more-expanded")) {
        element.classList.remove("see-more-expanded");
        event.currentTarget.innerHTML =
          'See More <i class="fa fa-angle-down"></i>';
      } else {
        element.classList.add("see-more-expanded");
        event.currentTarget.innerHTML =
          'See Less <i class="fa fa-angle-up"></i>';
      }
    });
  });
});
