document.addEventListener("DOMContentLoaded", () => {
  const captcha = document.getElementById("captcha")
  if (captcha) {
    const form = captcha.closest("form")
    // wait for the form to be focused to download the recaptcha js
    form.addEventListener(
      "focus",
      () => {
        const script = document.createElement("script")
        script.src = captcha.dataset.url
        script.async = "true"
        captcha.insertAdjacentElement("afterbegin", script)
      },
      { once: true, capture: true }
    );
  }

  const getTheBooks = document.querySelectorAll(".getTheBook")
  const convertkit = document.getElementById("formkit")
  if (convertkit && getTheBooks.length) {
    getTheBooks.forEach((element) => {
      // wait for the user to click the `Get the book` link to download the convertkit js
      element.addEventListener('click', (e) => {
        e.preventDefault()

        // if the form is already created, just click the link
        if (!document.querySelector(".formkit-slide-in")) {
          const script = document.createElement("script")
          script.src = convertkit.dataset.url
          script.dataset.uid = convertkit.dataset.uid
          script.async = "true"
          convertkit.insertAdjacentElement("afterbegin", script)
        }

        setTimeout(() => {
          element.click()
        }, 1000)
      }, {once: true})
    })
  }
})
