document.addEventListener('DOMContentLoaded', (event) => {
    // TODO: Refactor this. It's used only for the blog's anchors
    const NAVBAR_HEIGHT = document.getElementsByTagName('header')[0].offsetHeight;

    document.querySelectorAll('a[href^="#"]').forEach(link => {

        const ATTRIBUTE   = link.getAttribute('href');
        const SECTION_REF = document.querySelector(ATTRIBUTE);
        const OFFSET = NAVBAR_HEIGHT + 20;

        link.addEventListener('click', function (e) {
            e.preventDefault();
            if (!SECTION_REF) return;

            const urlWithHash = window.location.pathname + ATTRIBUTE;
            history.pushState({}, null, urlWithHash);

            const BODY    = document.body.getBoundingClientRect().top;
            const SECTION = SECTION_REF.getBoundingClientRect().top;

            const SECTION_POS = SECTION - BODY
            const OFFSET_POS  = SECTION_POS - OFFSET

            window.scrollTo({
                top: OFFSET_POS,
                behavior: 'smooth'
            })

        });

    });

    if(window.location.hash != null && window.location.hash.length > 0){

        window.scroll(0, 0)

        const HASH = window.location.hash
        const TARGET = document.querySelector(HASH)

        if (!TARGET) return;

        const DOCUMENT = document.body.getBoundingClientRect().top;
        const HASH_SECTION = TARGET.getBoundingClientRect().top;

        const HASH_POSITION = HASH_SECTION - DOCUMENT
        const HASH_OFFSET = HASH_POSITION - NAVBAR_HEIGHT

        setTimeout(function() {
            window.scrollTo({
                top: HASH_OFFSET,
                behavior: 'smooth'
            })
        });

    }


});
