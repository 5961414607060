document.addEventListener("DOMContentLoaded", () => {
  const sliderElement = document.getElementById("testimonials-slider");
  if (!sliderElement) return;

  const indicators = sliderElement.querySelectorAll("#slides-indicators li");
  const slides = sliderElement.querySelectorAll(".slide");

  let slideTimeoutId = null;
  function pauseSlider() {
    clearInterval(slideTimeoutId);
    slideTimeoutId = null;
  }

  function startSlider() {
    slideTimeoutId = setTimeout(nextSlide, 5000);
  }
  // observer callback to start/pause the slider when inside/outside the viewport
  function startSliderIfVisible(entries) {
    const entry = entries[0];
    if (entry.isIntersecting) {
      startSlider();
    } else {
      pauseSlider();
    }
  }

  let currentSlide = 0;
  function showSlide(index) {
    pauseSlider(); // we have to restart the timer when we change slides clicking the indicators
    const slideDirection = index < currentSlide ? "right" : "left";
    slides[currentSlide].className = `slide slide-out ${slideDirection}`;
    indicators[currentSlide].className = "";
    currentSlide = index;
    slides[currentSlide].className = `slide showing slide-in ${slideDirection}`;
    indicators[currentSlide].className = "active";
    startSlider();
  }

  // we have to clear the classes once the animations end for the next animation to work properly
  sliderElement.addEventListener("animationend", (event) => {
    if (event.target.classList.contains("slide-out")) {
      event.target.className = "slide";
    }
    if (event.target.classList.contains("slide-in")) {
      event.target.className = "slide showing";
    }
  });

  function nextSlide() {
    const next = (currentSlide + 1) % slides.length;
    showSlide(next);
  }

  document
    .querySelector("#slides-indicators")
    .addEventListener("click", (e) => {
      showSlide(e.target.dataset.slideTo);
    });

  const carouselObserver = new IntersectionObserver(startSliderIfVisible, {
    threshold: 0.7,
    rootMargin: "100px 0px -100px 0px",
  });

  carouselObserver.observe(sliderElement);

  // normalize the height of all carousel elements
  function carouselNormalization() {
    const heights = []; //create empty array to store height values
    let tallest; //create variable to make note of the tallest slide

    if (slides.length) {
      function normalizeHeights() {
        // add heights to array
        slides.forEach((el) => heights.push(el.clientHeight));
        // find largest value
        tallest = Math.max.apply(null, heights);
        // set same height to all elements
        slides.forEach((el) => (el.style.minHeight = tallest + "px"));
      }

      normalizeHeights();

      function adjustHeight() {
        // reset
        tallest = 0;
        heights.length = 0;
        slides.forEach((el) => (el.style.minHeight = "none"));

        //run it again
        normalizeHeights();
      }

      window.addEventListener("resize", adjustHeight);
      window.addEventListener("orientationchange", adjustHeight);
    }
  }

  carouselNormalization();
});
